import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import './App.css';
import './index.css';
import About from './About';

import Meow from './projects/Meow';
import MirrEcho from './projects/MirrEcho';
import PersonalityMusic from './projects/PersonalityMusic';
import PublicPrivate from './projects/PublicPrivate';
import Smarkly from './projects/Smarkly';
import Soli from './projects/Soli';
import PasswordProtectedBounce from './projects/PasswordProtectedBounce';

import logo from './img/logo.png';
import Meow_cover from './projects/imgMeow/cover.png';
import MirrEcho_cover from './projects/imgMirrEcho/cover.png';
import PersonalityMusic_cover from './projects/imgPersonalityMusic/cover.png';
import PublicPrivate_cover from './projects/imgPublicPrivate/cover.png';
import Smarkly_cover from './projects/imgSmarkly/cover.png';
import Bounce_cover1 from './projects/imgBounce/cover1.png';
import Soli_cover from './projects/imgSoli/cover.png';

function Navigation({ logo }) {
    const navigate = useNavigate();
    const handleNavigate = (path) => {
        if (path.startsWith('#')) {
            // It's an internal link, navigate within the page
            navigate('/');
            setTimeout(() => {
                const section = document.querySelector(path);
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        } else {
            // It's a route, use navigate to go to this route
            navigate(path);
        }
    };

    return (
        <header className="App-header">
            <a onClick={() => handleNavigate('#home')} style={{ cursor: 'pointer' }}>
                <img src={logo} alt="logo" className="App-logo" />
            </a>
            <nav>
                <ul>
                    <li className="nav-link">
                        <span onClick={() => handleNavigate('#projects')}>Projects</span>
                        <div className="dropdown-content">
                            <a onClick={() => handleNavigate('/project/meow')}>Meow</a>
                            <a onClick={() => handleNavigate('/project/soli')}>Soli</a>
                            <a onClick={() => handleNavigate('/project/smarkly')}>Smarkly</a>
                            <a onClick={() => handleNavigate('/project/bounce')}>Bounce Dryer Roll</a>
                            <a onClick={() => handleNavigate('/project/personalitymusic')}>MBTI - Music</a>
                            <a onClick={() => handleNavigate('/project/mirrecho')}>MirrEcho</a>
                            <a onClick={() => handleNavigate('/project/publicprivate')}>Public & Private</a>
                        </div>
                    </li>
                    {/*<li className="nav-link" onClick={() => handleNavigate('#projects')}>Projects</li>*/}
                    <li className="nav-link" onClick={() => handleNavigate('/about')}>About</li>
                </ul>
            </nav>
        </header>
    );
}


function App() {

    // Add your project details here
    const projects = [
        {
            id: 'meow',
            title: 'Meow : AI-Powered Cat Dairy',
            description: '#YOLO   #Hololens AR   #Unity   #Product Design',
            imageUrl: Meow_cover
        },
        {   id: 'soli',
            title: 'Soli',
            description: '#Product Design',
            imageUrl: Soli_cover 
        },
        {
            id: 'smarkly',
            title: 'Smarkly',
            description: '#Product Design   #Generative AI',
            imageUrl: Smarkly_cover
        },
        {
            id: 'bounce',
            title: 'Bounce Dryer Roll',
            description: '#Product Design   #Rebranding   #Packaging   #Marketing',
            imageUrl: Bounce_cover1
        },
        {
            id: 'personalitymusic',
            title: 'Decode Your Personality Through Melodies You Love',
            description: '#Interactive Web   #Data Visualization   #Javascript',
            imageUrl: PersonalityMusic_cover
        },
        {
            id: 'mirrecho',
            title: 'MirrEcho',
            description: '#Product Design   #Hololens AR   #Unity',
            imageUrl: MirrEcho_cover 
        },
        {   id: 'publicprivate',
            title: 'Public & Private',
            description: '#Generative AI   #OpenaiAPI   #Stable Diffusion   #React',
            imageUrl: PublicPrivate_cover 
        },
        
        
        // {   id: 'emotionalechoes',
        //     title: 'Emotional Echoes',
        //     description: '#Kinect   #Interactive Design   #Autistic Children',
        //     imageUrl: Meow_cover 
        // },
        // {   id: 'freePottery',
        //     title: 'Free Pottery',
        //     description: '#Web Design   #Javascript   #D3',
        //     imageUrl: Meow_cover 
        // }
    ];

    return (
        <Router>
            <div className="App">
                <Navigation logo={logo} />
                <Routes>
                    <Route exact path="/" element={
                        <main className="main-content">
                            <section id="home" className="intro leftAlign">
                                <h1 className="font-grey">
                                    <b className="font-black">
                                        <span style={{fontSize: '120%'}}>Hi, I'm Jyuan!</span>
                                    </b>
                                    <br/>
                                    I'm a <u className="font-black">product designer</u> dedicated to exploring <u className="font-black">technology-driven
                                    design and creative innovation</u>. Now pursuing a master's at Harvard University, focusing on design technology.
                                </h1>
                            </section>
                            <section id="projects" className="project-grid">
                                {projects.map((project, index) => (
                                    <Link to={`/project/${project.id}`}  key={index}>
                                        <img className="project-item" src={project.imageUrl} alt={project.title} />
                                        <div className="project-content">
                                            <h3 >{project.title}</h3>
                                            <p style={{whiteSpace: 'pre-wrap'}} className="font-grey">{project.description}</p>
                                        </div>
                                    </Link>
                                ))}
                            </section>
                        </main>
                    } />
                    <Route path="/about" element={<About />} />
                    <Route path="/project/meow" element={<Meow />} />
                    <Route path="/project/mirrecho" element={<MirrEcho />} />
                    <Route path="/project/personalitymusic" element={<PersonalityMusic />} />
                    <Route path="/project/publicprivate" element={<PublicPrivate />} />
                    <Route path="/project/smarkly" element={<Smarkly />} />
                    <Route path="/project/bounce" element={<PasswordProtectedBounce />} />
                    <Route path="/project/soli" element={<Soli />} />
                    
                </Routes>

                <footer>
                    <p className="footer">&copy; 2024 by Jyuan Liu. All Rights Reserved.</p>
                </footer>
            </div>
        </Router>
    );
}

export default App;
