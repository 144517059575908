import React, { useEffect } from 'react';
import '../App.css';
import cover from './imgPublicPrivate/cover.png';
import ideation from './imgPublicPrivate/ideation.png';
import diagram from './imgPublicPrivate/diagram.png';
import step1 from "./imgPublicPrivate/step1.png";
import step2 from "./imgPublicPrivate/step2.png";
import step3 from "./imgPublicPrivate/step3.png";
import test1 from "./imgPublicPrivate/test1.png";
import test2 from "./imgPublicPrivate/test2.png";
import test3 from "./imgPublicPrivate/test3.png";
import test4 from "./imgPublicPrivate/test4.gif";


const PublicPrivate = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <article className="PublicPrivate">
            <div className="singleProject">
                <img className="cover" src={cover} alt="Public & Private"/>
                <h2 className="section-margin">
                    Public & Private
                </h2>
                <div className="leftAlign font-grey">
                    <p>
                        "Public & Private" is a journey into <u>self-expression</u>. Within varied spheres, we calibrate
                        our words, images, and essence. From intimate whispers to public proclamations, we <u>tailor
                        our stories to each space we occupy</u>.
                        It is an interface that turns <u>a paragraph</u> into various <u>media forms</u>,
                        adapting it for audiences from close friends to social media like Instagram or Twitter. Users
                        input their experiences, then watch as their words and style change for different groups,
                        with <u>images and videos</u> to match. It highlights how we <u>edit ourselves for digital
                        identities</u>.
                    </p>
                </div>
                <div className="section-margin">
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Project Type :</b> Generative AI, OpenaiAPI, stable diffusion</p>
                        <p className="rightAlign"><b>Role :</b> Frontend, ideation</p>
                    </div>
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Date :</b> 2023</p>
                        <p><b>Duration :</b> 2 Weeks</p>
                        <p className="rightAlign"><b>Team :</b> Treyden, Matte</p>
                    </div>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">01 Ideation</h3>
                <img src={ideation} alt="ideation"/>
                <p className="font-grey">Diagram of the Software & Hardware</p>
                <img src={diagram} alt="Diagram of the Software & Hardware"/>
                <p className="font-grey">Testing process</p>
                <div className="gif-container">
                    <div>
                    {/*<p>i Entries are taken from reddit’s ‘confessions’ subreddit</p>*/}
                    <img className="function-gif" src={test1} alt="test1"/>
                    </div>
                    <div>
                    {/*<p>ii Select sharing choice and generate text</p>*/}
                    <img className="function-gif" src={test2} alt="test2"/>
                    </div>
                    <div>
                    {/*<p>iii Generate images</p>*/}
                    <img className="function-gif" src={test3} alt="test3"/>
                    </div>
                    <div>
                    {/*<p>iv Generate videos</p>*/}
                    <img className="function-gif" src={test4} alt="test4"/>
                    </div>
                </div>

                <h3 className="section-title section-margin">02 Output</h3>
                <div className="gif-container-3">
                    <p>i Select sharing choice and generate text</p>
                    <img className="function-gif" src={step1} alt="step1"/>
                    <p>ii Generate images</p>
                    <img className="function-gif" src={step2} alt="step2"/>
                    <p>iii Generate videos</p>
                    <img className="function-gif" src={step3} alt="step3"/>
                </div>

                <h3 className="section-title section-margin">03 Project Demo</h3>
                <div className="video-container">
                    <iframe
                        src="https://www.youtube.com/embed/oBbea4DnQu4?si=1YDuZjm58DvRn72b?rel=0"
                        title="Public & Private" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">04 List of Neural Networks</h3>
                <p className="leftAlign PP-reference">
                    <span className="reference">1. </span>
                    <a
                        href="https://openai.com/" target="_blank"
                        style={{color: 'black'}}>OpenAi Gpt-4</a><span className="reference">
                    : Brown, T., Mann, B., Ryder, N., Subbiah, M., Kaplan, J. D., Dhariwal, P., ... & Amodei, D. (2020). Language models are few-shot learners. Advances in neural information processing systems, 33, 1877-1901.
                </span></p>
                <p className="leftAlign PP-reference">
                    <span className="reference">2. </span>
                    <a
                        href="https://github.com/Stability-AI/generative-models" target="_blank"
                        style={{color: 'black'}}>Stable Diffusion XL</a><span className="reference">
                    : Rombach, R., Blattmann, A., Lorenz, D., Esser, P., & Ommer, B. (2022). High-resolution image synthesis with latent diffusion models. In Proceedings of the IEEE/CVF conference on computer vision and pattern recognition (pp. 10684-10695).
                </span></p>
                <p className="leftAlign PP-reference">
                    <span className="reference">3. </span>
                    <a
                        href="https://github.com/deforum-art/deforum-stable-diffusion" target="_blank"
                        style={{color: 'black'}}>Stable Diffusion Deforum</a><span className="reference">
                    : Esser, P., Chiu, J., Atighehchian, P., Granskog, J., & Germanidis, A. (2023). Structure and content-guided video synthesis with diffusion models. In Proceedings of the IEEE/CVF International Conference on Computer Vision (pp. 7346-7356).
                </span></p>
                <p className="leftAlign PP-reference">
                    <span className="reference">4. </span>
                    <a
                        href="https://www.assemblyai.com/" target="_blank"
                        style={{color: 'black'}}>Assembly AI</a><span className="reference">
                    : Qin, Y., Carlini, N., Cottrell, G., Goodfellow, I., & Raffel, C. (2019, May). Imperceptible, robust, and targeted adversarial examples for automatic speech recognition. In International conference on machine learning (pp. 5231-5240). PMLR.
                </span></p>
            </div>
        </article>
    );
};

export default PublicPrivate;
