import React, { useState, useEffect, useRef } from 'react';
import '../App.css';

import cover from './imgBounce/cover.png';
import title1 from './imgBounce/title1.png';
import title2 from './imgBounce/title2.png';
import title3 from './imgBounce/title3.png';

import background1 from './imgBounce/background1.png';
import background2 from './imgBounce/background2.png';
import background3 from './imgBounce/background3.png';
import background4 from './imgBounce/background4.png';
import target1 from './imgBounce/target1.png';
import target2 from './imgBounce/target2.png';
import target3 from './imgBounce/target3.png';
import target4 from './imgBounce/target4.png';
import anatomy from './imgBounce/anatomy.png';

import strength1 from './imgBounce/strength1.gif';
import strength2 from './imgBounce/strength2.gif';
import assets1 from './imgBounce/assets1.png';
import assets2 from './imgBounce/assets2.png';
import assets3 from './imgBounce/assets3.png';
import assets4 from './imgBounce/assets4.png';
import assets5 from './imgBounce/assets5.png';
import redesign1 from './imgBounce/redesign1.png';
import redesign2 from './imgBounce/redesign2.gif';
import redesign3 from './imgBounce/redesign3.gif';
import redesign4 from './imgBounce/redesign4.png';
import outcome0 from './imgBounce/outcome0.gif';
import outcome from './imgBounce/outcome.png';
import outcome1 from './imgBounce/outcome1.png';
import outcome2 from './imgBounce/outcome2.png';
import outcome3 from './imgBounce/outcome3.png';
import outcome4 from './imgBounce/outcome4.gif';
import outcome5 from './imgBounce/outcome5.gif';
import outcome6 from './imgBounce/outcome6.gif';
import outcome7 from './imgBounce/outcome7.gif';
import outcome8 from './imgBounce/outcome8.gif';
import outcome9 from './imgBounce/outcome9.gif';
import refill1 from './imgBounce/refill1.gif';
import refill2 from './imgBounce/refill2.gif';
import refill3 from './imgBounce/refill3.gif';
import refill4 from './imgBounce/refill4.png';
import refill5 from './imgBounce/refill5.png';
import scenario1 from './imgBounce/scenario1.png';
import scenario2 from './imgBounce/scenario2.png';
import scenario3 from './imgBounce/scenario3.png';

import offline1 from './imgBounce/offline1.png';
import offline2 from './imgBounce/offline2.png';
import offline3 from './imgBounce/offline3.png';
import online from './imgBounce/online.png';
import manufacturing from './imgBounce/manufacturing.png';
import price from './imgBounce/price.png';
import ad from './imgBounce/ad.png';
import ad1 from './imgBounce/ad1.png';
import ad2 from './imgBounce/ad2.png';
import ad3 from './imgBounce/ad3.png';
import campaign1 from './imgBounce/campaign.gif';
import campaign2 from './imgBounce/campaign1.png';
import campaign3 from './imgBounce/campaign2.png';

import end1 from './imgBounce/end1.png';
import end2 from './imgBounce/end2.png';

const Bounce = () => {
    const [activeSection, setActiveSection] = useState(null);
    const [hovered, setHovered] = useState(null);
    const sectionRefs = useRef([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Function to toggle the sections
    const toggleSection = (section) => {
        setActiveSection((prevSection) => {
            const newSection = prevSection === section ? null : section;
    
            // Always scroll to the first section image (section 1)
            if (sectionRefs.current[1]) {
                sectionRefs.current[1].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start', // Scroll to the top of section 1 image
                });
            }
    
            return newSection; // Set the active section
        });
    };
    

    return (
        <article className="Bounce">
            <div className="singleProject">
                <img className="cover" src={cover} alt="Bounce"/>
                <h2 className="section-margin">
                    BOUNCE DRYER ROLL : New Extension to the Bounce Family
                </h2>
                <p className="leftAlign font-grey">
                Bounce Dryer Roll is a <u>new addition</u> to the Bounce family. The new product caters to a traditionally overlooked demographic--<u>men</u>, especially those navigating significant life stage changes and searching for opportunities to boost their confidence through self-care. This strategic move not only keeps Bounce at the forefront of the dryer sheet market but also <u>widens our customer base</u>, ensuring our continued financial growth and market dominance.
                </p>
                {/* <p className="leftAlign font-grey">
                    Design Process:
                    <li>Brand Strategy -- Brand & Market Analysis, Target Market.</li>
                    <li>Product Design -- Brand Assets Analysis, Packaging Redesign.</li>
                    <li>Growth Strategy -- Retail, Pricing, Manufacturing, Marketing.</li>
                </p> */}
                <div className="section-margin">
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Project Type :</b> Rebranding, Product design, Marketing</p>
                        <p className="rightAlign"><b>Role :</b> User Research, Marketing Analysis, Product Design</p>
                    </div>
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Date :</b> 2023</p>
                        <p className="centerAlign"><b>Duration :</b> 14 Weeks</p>
                        <p className="rightAlign"><b>Team :</b> Angel, Nana, Abdukarim, Somin</p>
                    </div>
                </div>

                {/* * * * * * * * * * * * * * * * * /
                /*      01  Brand Strategy        */
                /* * * * * * * * * * * * * * ** * */}
                <div className="section-margin"></div>
                <img
                    ref={(el) => (sectionRefs.current[1] = el)}
                    className="function-gif"
                    src={title1}
                    alt="title1"
                    onClick={() => toggleSection(1)} // Click to toggle section 1
                    style={{
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease-in-out',
                        transform: hovered === 1 ? 'scale(1.1)' : 'scale(1)', // Apply scale on hover
                    }}
                    onMouseEnter={() => setHovered(1)} // Set hover state when mouse enters
                    onMouseLeave={() => setHovered(null)} // Reset hover state when mouse leaves
                />

                {activeSection === 1 && (
                    <div>
                    <h3 className="section-title section-margin">01 Brand & Market Analysis</h3>
                    <div className="gif-container">
                        <p className="leftAlign font-grey">
                            <p className="leftAlign font-black">
                                • Who Bounce is -- Brand Identity
                            </p>
                            <br/>
                            <p>
                                Bounce was the first dryer sheet to hit the market back in 1976 as a fabric softener alternative. Bounce currently has six different products under its brand, all with the mission to reduce statics & wrinkles, repel lint, and soften fabric. Bounce's parent company is Procter and Gamble, which owns over 76 brands, including other laundry products like Tide.     
                            </p>
                        </p>
                        <img className="function-gif" src={background1} alt="background1"/>
                        <p className="leftAlign font-grey">
                            <p className="leftAlign font-black">
                                • Where we stand in the market -- Competitors Analysis 
                            </p>
                            <br/>
                            <p>
                                <u>Audience</u>: Competitors focus on women and families, offering floral scents, while Bounce can appeal to single professional men with a new, distinct product.
                            </p>
                            
                            <p>
                                <u>Pricing</u>: Natural-based competitors are significantly more expensive, and air-drying is impractical for smaller spaces, giving Bounce the opportunity to charge more while remaining affordable.
                            </p>

                        </p>
                        <img className="function-gif" src={background2} alt="background2"/>
                        <p className="leftAlign font-grey">
                            <p className="leftAlign font-black">
                                • Where is the Blue Ocean -- Market Trends 
                            </p>
                            <br/>
                            <p>
                                <u>Expanding Market</u>: The global electric clothes dryer market is set to <b>increase up to 46% by 2030 </b> and the dryer sheetmarket is growing with an expected <b>increase of 4.11% (CAGR) by 2031</b>.
                            <p></p>
                                <u>Single Men</u>: More men are single, with around <b>60% of young men</b> not in a relationship.
                            <p></p>
                                <u>Men Doing Laundry</u>: More men are doing their own laundry with over <b>55% of men aged 18+</b> doing their own laundry
                            <p></p>
                                <u>Low Male Confidence</u>: <b>40% of men</b> report issues with self confidence.
                            <p></p>
                                <u>Men Spending on Personal Care</u>: North America is the fastest growing market for men's personal care products, with expected growth by almost <b>10% by 2030</b>.
                            </p>
                            
                        </p>
                        <img className="function-gif" src={background3} alt="background3"/>
                        <div className="section-margin"></div>
                        <div className="section-margin"></div>
                        <p className="leftAlign font-grey">
                            <p className="centerAlign font-black">
                                <b>Brand Architecture</b> 
                                <p>Extension of Bounce Family</p>
                            </p>
                            <p>
                                Bounce currently offers traditional dryer sheets in different scents and offers a mega sheet line, which targets households with larger laundry loads. We want to create a <u>new product under the Bounce brand designed around blue-ocean needs</u>.
                            </p>
                        </p>
                        <img className="function-gif" src={background4} alt="background4"/>
                    </div>
                    <h3 className="section-title section-margin">02 Target Market</h3>
                    <div className="gif-container">
                        <p className="leftAlign font-grey">
                            <p className="leftAlign font-black">
                                • User Needs Analysis -- D.E.R.T.s
                            </p>
                            <br/>
                            <p>
                                New Bounce targets men going through life Transitions feeling Disconnected, Embarrassed, and Rumpled. D.E.R.T.s need to boost their confidence and self-care. 
                            </p><p>
                                Whether they’re recent grads, new city residents, or newly divorced, Bounce can help them dress better, smell good, and feel more presentable, leveraging market growth to empower these men and address their unique needs.
                            </p>
                        </p>
                        <img className="function-gif" src={target1} alt="target1"/>
                        <p className="leftAlign font-grey">
                            <p className="leftAlign font-black">
                                • Past Attempt and Current Box Limitations 
                            </p>
                            <br/>
                            <p>
                                <u>Past Attempt</u>: Bounce's "Bounce for Men" in 2014 was discontinued due to an unpopular scent and overlooked design considerations.
                            </p>
                            <p>
                                <u>Box Limitations</u>: Current Bounce packaging is awkward, non-resealable, and not well-suited for single-person households. There is also a lack of awareness about dryer sheet benefits and alternative uses.
                            </p>
                        </p>
                        <img className="function-gif" src={target2} alt="target2"/>
                        <p className="leftAlign font-grey">
                            <p className="leftAlign font-black">
                                • User Journey Map -- How they do laundry
                            </p>
                            <br/>
                            <p>
                                D.E.R.T.s <u>Laundry Pile</u> is overwhelming and overdue.
                            </p>
                            <p>
                                <u>Typical Laundry Day</u>: We are focusing on the needs of D.E.R.T.s who use Bounce while going to laundromats or using in-building laundry facilities. The design aims to address the challenge of carrying all necessary items.
                            </p>
                            
                        </p>
                        <img className="function-gif" src={target3} alt="target3"/>
                        <p className="leftAlign font-grey">
                            <p className="leftAlign font-black">
                                • Target Market Analysis 
                            </p>
                            <br/>
                            <p>
                            The new market consists of up to <b>19.8 million</b> single men above the poverty line. Convenient detergent designs like pods and dissolvable sheets are priced <b>2.5x higher</b> than regular products. We can similarly increase Bounce's price from <b>5 cents</b> to <b>up to 13 cents</b> per sheet by <u>enhancing convenience and reducing the number of sheets per package</u>.
                            </p>
                        </p>
                        <img className="function-gif" src={target4} alt="target4"/>
                    </div>

                    <h3 className="section-title">03 Brand Anatomy</h3>
                    <img className="function-gif" src={anatomy} alt="anatomy" style={{ width: '66.67%', display: 'block', margin: '0 auto' }} />
                    
                    </div>
                )}

                {/* * * * * * * * * * * * * * * * * /
                /*    02    Product Design        */
                /* * * * * * * * * * * * * * ** * */}

                <div className="section-margin"></div>
                <img
                    ref={(el) => (sectionRefs.current[2] = el)}
                    className="function-gif"
                    src={title2}
                    alt="title2"
                    onClick={() => toggleSection(2)}
                    style={{
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease-in-out',
                        transform: hovered === 2 ? 'scale(1.1)' : 'scale(1)',
                    }}
                    onMouseEnter={() => setHovered(2)}
                    onMouseLeave={() => setHovered(null)}
                />
                {activeSection === 2 && (
                <div>
                <h3 className="section-title section-margin">01 Bounce’s Brand Assets</h3>
                
                <p className="section-title"><b>i Strength</b></p>
                    <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    <u>Asset Strength -- Bold, Recognizable, and Easy to Follow</u>: There are many assets of the Bounce brand we wanted to keep and emphasize when creating a new product offering for bounce.
                    </p>
                    <img className="function-gif" src={strength1} alt="strength1" style={{ width: '66.67%', display: 'block', margin: '0 auto' }} />
                    <div className="section-margin"></div>
                    <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    <u>Personality -- Humorous, Energetic, Bold, and Accessible</u>: Our Brand's personality comes through in Bounce's marketing strategy and is humorous, energetic, bold, and accessible.
                    </p>
                    <img className="function-gif" src={strength2} alt="strength1" style={{ width: '66.67%', display: 'block', margin: '0 auto' }} />
                
                <p className="section-title"><b>ii Assets</b></p>
                <div className="gif-container-2">
                    <img className="function-gif" src={assets1} alt="assets1"/>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                        • Color</p><br/><p>
                        Bounce has a distinct brand color and clear benefits. In the redesign, we focused on accessibility, ensuring strong logo contrast for color blindness and using symbols and sans serif fonts to improve clarity for dyslexic users.
                        </p>
                    </p>
                    <img className="function-gif" src={assets2} alt="assets2"/>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                            • Tagline</p><br/><p>
                            Bounce’s tagline changes with different campaigns and doesn’t appear on the box. We're proposing a new one, "Get Your Bounce Back," to highlight self-improvement, and plan to add it to the packaging.
                        </p>
                    </p>
                    <img className="function-gif" src={assets3} alt="assets3"/>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                            • Logo</p><br/><p>
                            We're keeping the current Bounce logo, as it's a strong asset and was updated in 2019.
                            </p>
                    </p>
                    <img className="function-gif" src={assets4} alt="assets4"/>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                            • Typography & Fonts</p><br/><p>
                        The package currently uses six fonts. We tested various options and narrowed it down to two fonts.
                        </p>
                    </p>
                </div>
                <img className="function-gif" src={assets5} alt="assets5" style={{ width: '66.67%', display: 'block', margin: '0 auto' }} />

                <h3 className="section-title section-margin">02 Packaging Redesign</h3>
                <p className="section-title"><b>i Design Goals</b></p>
                <div className="gif-container-3">
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                        We designed the product to maintain its multi-purpose uses, such as freshening cars and luggage, and to optimize size for shipping and shelving. We aimed to enhance portability and convenience, introduce it to new locations like men’s grooming or checkout aisles, and ensure it remains competitively priced.</p>
                    </p>
                    <img className="function-gif" src={redesign1} alt="redesign1"/>
                </div>
                <p className="section-title"><b>ii Design Process</b></p>
                
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                <b>Design Ideas</b> <br/>Here’s a glimpse into our semester-long collaborative process, where we brainstormed, iterated, and shared ideas using our Team Miro board.
                </p>
                <img className="function-gif" src={redesign2} alt="redesign2" style={{ width: '66.67%', margin: '0 auto' }} />
                
                <div className="section-margin"></div>
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                <b>Physical Prototypes</b></p>
                <img className="function-gif" src={redesign3} alt="redesign3" style={{ width: '66.67%', margin: '0 auto' }} />
                
                <div className="section-margin"></div>
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                <b>Digital Prototypes</b></p>
                <img className="function-gif" src={redesign4} alt="redesign4" style={{ width: '66.67%', margin: '0 auto' }} />
                
                <h3 className="section-title section-margin">03 Prototypes Outcome</h3>
                <img className="function-gif" src={outcome0} alt="outcome0" style={{ width: '66.67%', margin: '0 auto' }} />
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                The Bounce Dryer Roll is innovative and disrupts the dryer box category. The new offering proposed by our team creates an extension of the line with an introduction of a more efficient and portable package size, whilst maintaining its easy usage.
                </p>
                <img className="function-gif" src={outcome1} alt="outcome1" style={{ width: '66.67%', margin: '0 auto' }} />
                <img className="function-gif" src={outcome} alt="outcome" style={{ width: '66.67%', margin: '0 auto' }} />
                <div className="section-margin"></div>

                <p className="section-title"><b>i Dispenser</b></p>
                <img className="function-gif" src={outcome2} alt="outcome2" style={{ width: '66.67%', margin: '0 auto' }} />
                <img className="function-gif" src={outcome3} alt="outcome3" style={{ width: '90%', margin: '0 auto' }} />
                
                <div className="section-margin"></div>
                <img className="function-gif" src={outcome4} alt="outcome4" style={{ width: '66.67%', margin: '0 auto' }} />
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    The reusable plastic body holds 40 dryer sheets and is refillable. It features a translucent orange top, allowing users to see when it's time for a refill. The roll is designed to fit quarters inside the central tube, with an inner shell containing the scent. The opening slit holds each sheet in place, and the clip accommodates various finger sizes for easy use. Simple text guides users on where to place coins, clip, and pull.
                </p>

                <img className="function-gif" src={outcome9} alt="outcome9" style={{ width: '66.67%', margin: '0 auto' }} />
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    To use a dryer sheet, simply pull a sheet from the roll and tear it at the perforated seam.
                </p>

                <div className="section-margin"></div>
                <img className="function-gif" src={outcome5} alt="outcome5" style={{ width: '66.67%', margin: '0 auto' }} />
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    <b>Paper Cover Dielines</b>: The paper cover and slip protect the product during shipping and shelving, featuring a hang hole and billboard space for supermarkets. The slip includes essential product information and introduces "Bounce Dryer Sheets in a Roll." We've also developed icons to guide users on sheet usage by load size and how to refill the product.
                </p>

                <div className="section-margin"></div>
                <img className="function-gif" src={outcome6} alt="outcome6" style={{ width: '66.67%', margin: '0 auto' }} />
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    <b>Dispenser Dielines</b>: The main plastic dispenser maintains only the essential information, including the usage and refill instructions. 
                </p>

                <div className="section-margin"></div>

                <p className="section-title"><b>ii Refills</b></p>

                <div className="section-margin"></div>
                <img className="function-gif" src={refill4} alt="refill4" style={{ width: '66.67%', margin: '0 auto' }} />
                <div className="section-margin"></div>
                <img className="function-gif" src={refill1} alt="refill1" style={{ width: '66.67%', margin: '0 auto' }} />
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    <b>Singles</b>: We're introducing refills, sold as individual rolls with Bounce's signature Outdoor Fresh scent. In the future, we plan to expand to Fresh Linen and Fragrance-Free options. The refill packaging will include essential product information and refill instructions, consistent with the main dispenser.
                </p>
                
                <div className="section-margin"></div>
                <img className="function-gif" src={refill5} alt="refill5" style={{ width: '66.67%', margin: '0 auto' }} />
                <div className="section-margin"></div>
                <img className="function-gif" src={refill2} alt="refill2" style={{ width: '66.67%', margin: '0 auto' }} />
                <div className="section-margin"></div>
                <img className="function-gif" src={refill3} alt="refill3" style={{ width: '66.67%', margin: '0 auto' }} />
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    <b>Boundle</b>: We are introducing bundle refills with three rolls, allowing customers to save while purchasing more. These bundles will be displayed alongside the main dispenser and single refills. The packaging features a prominent front logo and a back with clear subdivision markings for easy tear-off. It highlights the product's ergonomic design for easy transport and includes refill instructions, usage details, and the product's function as a dryer roll refill. The tagline will be discussed later.
                </p>

                <div className="section-margin"></div>

                <p className="section-title"><b>iii Physical Prototypes</b></p>
                <img className="function-gif" src={outcome7} alt="outcome7" style={{ width: '66.67%', margin: '0 auto' }} />
                <div className="section-margin"></div>
                <img className="function-gif" src={outcome8} alt="outcome8" style={{ width: '66.67%', margin: '0 auto' }} />

                <p className="section-title"><b>iv User Scenario</b></p>
                <div className="gif-container-3">
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                        The Bounce Dryer roll clips onto laundry baskets and bags making it very portable.
                        </p>
                    </p>
                    <img className="function-gif" src={scenario1} alt="scenario1"/>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                        It’s designed with careful attention to detail to ensure that each dryer sheet from the roll is held at the opening and is easy to pull out.
                        </p>
                    </p>
                    <img className="function-gif" src={scenario2} alt="scenario2"/>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                        Quarters can be stored inside the roll, which is especially useful for those who use coin laundry to organize and minimize the number of things needed to be carried back and forth. 
                        </p>
                    </p>
                    <img className="function-gif" src={scenario3} alt="scenario3"/>
                </div>
                </div>
                )}

                <div className="section-margin"></div>

                {/* * * * * * * * * * * * * * * * * /
                /*     03   Growth Strategy       */
                /* * * * * * * * * * * * * * ** * */}

                <div className="section-margin"></div>
                <img
                    ref={(el) => (sectionRefs.current[3] = el)}
                    className="function-gif"
                    src={title3}
                    alt="title3"
                    onClick={() => toggleSection(3)} // Click to toggle section 3
                    style={{
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease-in-out',
                        transform: hovered === 3 ? 'scale(1.1)' : 'scale(1)', // Apply scale on hover
                    }}
                    onMouseEnter={() => setHovered(3)} // Set hover state when mouse enters
                    onMouseLeave={() => setHovered(null)} // Reset hover state when mouse leaves
                />
                {activeSection === 3 && (
                <div>
                <h3 className="section-title section-margin">01 Retail Distribution Strategy--Where it will show up in stores</h3>
                <p className="section-title"><b>i Supermarket Shelves: Offline Presence</b></p>
                <div className="gif-container">
                    <p className="leftAlign font-black">
                        <li><u>Laundry Aisle</u>: Breaking the Box Mold</li>
                        <p className="leftAlign font-grey">Aim to stand out in the laundry aisle by breaking away from traditional packaging and presentation.</p><br/>
                        <li><u>Checkout Aisle</u>: Checkout Visibility</li>
                        <p className="leftAlign font-grey">Explore opportunities for Bounce dryer rolls to appear in the checkout aisle to increase visibility and impulse purchases.</p><br/>
                        <li><u>Convenience and Men’s Grooming Aisle</u>: Hanging Grab-and-Go</li>
                        <p className="leftAlign font-grey">Position the product in hanging grab-and-go sections of convenience stores and the men’s grooming aisle to expand awareness, accessibility, and attract male customers.</p><br/>
                    </p>
                    <img className="function-gif" src={offline1} alt="offline1"/>
                    <img className="function-gif" src={offline2} alt="offline2"/>
                    <img className="function-gif" src={offline3} alt="offline3"/>
                </div>
                <p className="section-title"><b>ii E-Commerce: Online Presence</b></p>
                <div className="gif-container">
                    <p className="leftAlign font-grey">
                        We'll also break that box mold on e-commerce websites to stand out from other laundry products there
                    </p>
                    <img className="function-gif" src={online} alt="online"/>
                </div>
                <h3 className="section-title section-margin">02 Price Pack Architecture</h3>
                    <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    Because our product adds novelty, convenience, and single household sizing that gives us more freedom to position Bounce Dryer Rolls at a higher price point. 
                    </p>
                    <img className="function-gif" src={price} alt="price" style={{ width: '66.67%', display: 'block', margin: '0 auto' }} />
                <h3 className="section-title section-margin">03 Manufacturing: Process Optimization</h3>
                <div className="gif-container">
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">• <u>Market Fit</u></p>
                        <p>The dryer roll should align with Bounce’s existing manufacturing ecosystem.
                        </p>
                    </p>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">• <u>Manufacturing Efficiency</u></p>
                        <p>Using a roll form eliminates steps like unspooling, cutting, and stacking, saving on floor space, machinery, and expertise.
                        </p>
                    </p>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">• <u>Cost Savings</u></p>
                        <p>Simplifying the process increases the per sheet price but results in overall savings on manufacturing costs.
                        </p>
                    </p>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">• <u>Leveraging Existing Supply Chains</u></p>
                        <p>Plan to use high-density polyethylene for the plastic dispenser, similar to Tide’s packaging, benefiting from established supply chains and expertise.
                        </p>
                    </p>
                </div>
                    <img className="function-gif" src={manufacturing} alt="manufacturing" style={{ width: '66.67%', display: 'block', margin: '0 auto' }} />
                

                <h3 className="section-title section-margin">04 Marketing</h3>
                <p className="section-title">i Media Mix</p>
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    The ad placement strategy for the Bounce Dryer Roll campaign will use a mix of offline and online media, including local TV and paid social, to maximize reach across different age groups in the target audience (DERTs). Each channel will have a unique theme color in the ads, such as blue on platforms like Blind to build trust.
                </p>
                <img className="function-gif" src={ad} alt="ad" style={{ width: '66.67%', display: 'block', margin: '0 auto' }} />
                <div className="gif-container-2">
                    <img className="function-gif" src={ad1} alt="ad1"/>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                        • Bus Stop
                        </p>
                        <br/>
                        <p>
                        For out-of-home advertisements, we will have displays in public spaces such as bus stops to spread the visibility of the new roll form.
                        </p>
                    </p>
                    <img className="function-gif" src={ad2} alt="ad2"/>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                            • Print Ad (AARP)
                        </p>
                        <br/>
                        <p>
                            We will have ads in print magazines such as AARP which older DERTs read.
                        </p>
                        <p>
                            <u>Typical Laundry Day</u>: We are focusing on the needs of D.E.R.T.s who use Bounce while going to laundromats or using in-building laundry facilities. The design aims to address the challenge of carrying all necessary items.
                        </p>
                        
                    </p>
                    <img className="function-gif" src={ad3} alt="ad3"/>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                            • Online Display & Paid Social
                        </p>
                        <br/>
                        <p>
                        We will also advertise on job platforms like Blind to market to DERTs who are young professionals that want to boost their confidence and on Tinder to market to DERTs who want to look good for dates.
                        </p>
                    </p>
                    <div className="video-container"><iframe
                        src="https://www.youtube.com/embed/8SSgDWkWsSE?si=O2MjcPnLPyejTjGv?rel=0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe></div>
                    <p className="leftAlign font-grey">
                        <p className="leftAlign font-black">
                            • TV advertisements
                        </p>
                        <br/>
                        <p>
                            We invited two male actors to shoot a 1-minute video advertisement.
                        </p>
                    </p>
                </div>


                <div className="section-margin"></div>
                <p className="section-title">ii New Product Campaign</p>
                <div className="gif-container">
                    <img className="function-gif" src={campaign1} alt="campaign1"/>
                    <img className="function-gif" src={campaign2} alt="campaign2"/>
                </div>
                <div className="gif-container-2">
                    <img className="function-gif" src={campaign3} alt="campaign3"/>
                    <p className="leftAlign font-grey">
                    The <b>"Get Rollin' Anywhere" campaign</b> promotes the new Bounce Dryer Roll by emphasizing its portability, versatility, and ease of use. The tagline <b>"Get Your Bounce Back"</b> positions the product as part of self-care and self-improvement. The campaign also focuses on educating consumers about this new product category in the dryer market.
                    </p>
                </div>
                </div>
                )}



                <div className="section-margin"></div>
                <img className="function-gif" src={end2} alt="end2"/>
                <div className="section-margin"></div>
                
                <img className="function-gif" src={end1} alt="end1" style={{ width: '66.67%', display: 'block', margin: '0 auto' }} />
                <div className="section-margin"></div>
                
                <h3 style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    <b style={{ color: '#F2683F' }}>Bounce Dryer Roll</b>
                    , the new product, will provide ease and convenience in the laundry room to address the needs of 
                    <b style={{ color: '#F2683F' }}>DERTs</b>.
                </h3>
                <div className="section-margin"></div>
            </div>
        </article>
    );
};

export default Bounce;
