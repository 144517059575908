import React, { useEffect } from 'react';
import '../App.css';
import cover from './imgMirrEcho/cover.png';
import mechanism from './imgMirrEcho/mechanism.png';
import techflow from './imgMirrEcho/techflow.png';
import test1 from "./imgPublicPrivate/test1.png";


const MirrEcho = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <article className="MirrEcho">
            <div className="singleProject">
                <img className="cover" src={cover} alt="MirrEcho"/>
                <h2 className="section-margin">
                    MirrEcho : Your Personal Emotional Companion
                </h2>
                <div className="leftAlign font-grey">
                    <p>
                        MirrEcho is an app designed to <u>monitor and interact</u> with users' <u>emotions</u> in real
                        time.
                        Utilizing <u>AR technology</u>, it introduces an avatar that <u>laughs with you</u> when it
                        detects
                        laughter, <u>enhancing joyful moments</u>. Conversely, if decreased activity, social engagement,
                        or sleep quality are observed, the avatar appears to <u>remind</u> you to be mindful of your <u>
                        mental state</u>. This innovative approach ensures users are consistently aware of and
                        encouraged
                        to maintain <u>positive mental</u> well-being, making MirrEcho a unique companion in the journey
                        toward <u>emotional health and happiness</u>.
                    </p>
                </div>
                <div className="section-margin">
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Project Type :</b> Product design, AR </p>
                        <p className="rightAlign"><b>Role :</b> Technique workflow, prototyping, AR</p>
                    </div>
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Date :</b> 2023</p>
                        <p><b>Duration :</b> 3 Weeks</p>
                        <p className="rightAlign"><b>Team :</b> Sijia</p>
                    </div>
                </div>


                <div className="section-margin"></div>
                <h3 className="section-title section-margin">01 Ideation</h3>


                <div className="gif-container leftAlign">
                    <p className="font-black">
                        • Laugh Along with Your Avatar:
                        <br/>
                        <span className="font-grey">
                        When MirrEcho detects laughter through your AR glasses, it
                        brings to life a friendly avatar that joins in your joy, laughing alongside you. This unique
                        feature amplifies positive emotions, making happy moments even more memorable. The core philosophy of
                        Mirr Echo is to promote a positive mental state. By providing real-time emotional support and
                        reminders, it helps you stay balanced, joyful, and emotionally healthy.
                        </span>
                    </p>
                    <div className="video-container">
                        <iframe
                            src="https://www.youtube.com/embed/xYqoVlJDVXw?si=36mMT8YAt3oFoHII?rel=0"
                            title="MirrEcho : Your Personal Emotional Companion" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <p className="font-black">
                        • Mindful Reminders for Mental Well-being:
                        <br/>
                        <span className="font-grey">
                        In today's fast-paced world, it's easy to overlook
                        our mental health. MirrEcho is here to change that. When it detects signs of potential emotional
                        distress – like decreased activity, reduced social interactions, or poor sleep quality – your
                        avatar gently appears. It serves as a kind reminder to check in with yourself, encouraging
                        self-awareness and proactive care for your mental state.
                        </span>
                    </p>
                    <div className="video-container">
                        <iframe
                            src="https://www.youtube.com/embed/y3V2McZHusA?si=YS2e7j7PDGzuoPkl?rel=0"
                            title="MirrEcho : Your Personal Emotional Companion" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>


                <h3 className="section-title section-margin">02 Prototyping</h3>
                <p className="font-grey">Interaction Mode & Reward Mechanism</p>
                <img src={mechanism} alt="Interaction Mode & Reward Mechanism"/>

                <p className="font-grey">Technical Pipeline</p>
                <img src={techflow} alt="Technical Pipeline"/>


                <h3 className="section-title section-margin">03 Project Demo</h3>
                <div className="video-container">
                    <iframe
                        src="https://www.youtube.com/embed/nMRcUKBzwmg?si=5T3Y6cAiuOoG6hFF?rel=0"
                        title="MirrEcho : Your Personal Emotional Companion" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>

            </div>
        </article>
    );
};

export default MirrEcho;
