import React, { useEffect } from 'react';
import '../App.css';
import cover from './imgPersonalityMusic/cover.png';
import sketch from './imgPersonalityMusic/sketch.png';
import funtionGIF1 from './imgPersonalityMusic/1.gif';
import funtionGIF2 from './imgPersonalityMusic/2.gif';
import funtionGIF3 from './imgPersonalityMusic/3.gif';
import funtionGIF4 from './imgPersonalityMusic/4.gif';
import funtionGIF5 from './imgPersonalityMusic/5.gif';
import funtionGIF6 from './imgPersonalityMusic/6.gif';
import funtionGIF7 from './imgPersonalityMusic/7.gif';
import funtionGIF8 from './imgPersonalityMusic/8.gif';
import funtionGIF9 from './imgPersonalityMusic/9.gif';
import funtionGIF10 from './imgPersonalityMusic/10.gif';


const PersonalityMusic = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <article className="PersonalityMusic">
            <div className="singleProject">
                <img className="cover" src={cover} alt="PersonalityMusic"/>
                <h2 className="section-margin">
                    Decode Your Personality Through Melodies You Love
                </h2>
                <div className="leftAlign font-grey">
                    <p>
                        Experience the captivating fusion of <u> music and personality</u> with our <u> interactive web
                        project</u>,
                        <u> "Decode Your Personality Through Melodies You Love." </u>Music, an art form that resonates
                        with the
                        soul, has the power to evoke deep emotions and reflect the nuances of our inner selves.
                        Similarly, the Myers-Briggs Type Indicator (MBTI), revered for its insights into personality,
                        helps us understand our intrinsic traits and preferences. Can the melodies you love provide a
                        window into your
                        <u> unique traits and preferences</u>? Do your <u>MBTI types influence</u> your musical tastes?
                        In this <u>engaging and playful online experience</u>, we explore the intriguing connection
                        between
                        your favorite music and your personality.
                    </p>
                </div>
                <div className="section-margin">
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Project Type :</b> Interactive Web, data Visualization, Javascript,
                            D3</p>
                        <p className="rightAlign"><b>Role :</b> Frontend, storyboard sketch, data collection </p>
                    </div>
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Date :</b> 2023</p>
                        <p><b>Duration :</b> 4 Weeks</p>
                        <p className="rightAlign"><b>Team :</b> Clara, Lingbo</p>
                    </div>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">01 Storyboard</h3>
                <img className="personality-sketch" src={sketch} alt="storyboard sketch"/>

                <h3 className="section-title section-margin">02 Website Function</h3>
                <div className="gif-container">
                    <div>
                        <p>i Instructions: clarify the theme of the webiste</p>
                        <img className="function-gif" src={funtionGIF1} alt="Function GIF 1"/>
                    </div>
                    <div>
                        <p>ii MBTI: 16 Personalities: get an overview of personality genres</p>
                        <img className="function-gif" src={funtionGIF2} alt="Function GIF 3"/>
                    </div>
                    <div>
                        <p>iii Personality Types: provide detailed information about MBTI</p>
                        <img className="function-gif" src={funtionGIF3} alt="Function GIF 5"/>
                    </div>
                    <div>
                        <p>iv MBTI World Map</p>
                        <img className="function-gif" src={funtionGIF4} alt="Function GIF 7"/>
                    </div>
                    <div>
                        <p>v Sound and Psyche: Visualize research papers</p>
                        <img className="function-gif" src={funtionGIF5} alt="Function GIF 9"/>
                    </div>
                    <div>
                        <p>vi Music Genres: music information</p>
                        <img className="function-gif" src={funtionGIF6} alt="Function GIF 2"/>
                    </div>
                    <div>
                        <p>vii Melody & Persona: Uncover Music and Personality Connection</p>
                        <img className="function-gif" src={funtionGIF7} alt="Function GIF 4"/>
                    </div>
                    <div>
                        <p>viii Find your Connection</p>
                        <img className="function-gif" src={funtionGIF8} alt="Function GIF 6"/>
                    </div>
                    <div>
                        <p>ix Explore detailed connections between Music & Persona</p>
                        <img className="function-gif" src={funtionGIF9} alt="Function GIF 8"/>
                    </div>
                    <div>
                        <p>x Reference</p>
                        <img className="function-gif" src={funtionGIF10} alt="Function GIF 10"/>
                    </div>
                </div>

                <h3 className="section-title section-margin">03 Project Demo</h3>
                <div className="video-container">
                    <iframe
                        src="https://www.youtube.com/embed/Td17Tkf5u8E?si=gj7f2ijiP6q4LK3W?rel=0"
                        title="Decode Your Personality Through Melodies You Love" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
                <p className="font-black">Try on for a better experience on a large screen <b><a
                    href="https://quanyoya.github.io/MBTI-Music/" target="_blank"
                    style={{color: 'black'}}>https://quanyoya.github.io/MBTI-Music/</a></b></p>

            </div>
        </article>
    );
};

export default PersonalityMusic;
